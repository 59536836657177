<template>
  <div class="navRight">
    <div class="top flex-sb">
      <h3 style="font-size: 1.25rem">{{ t('160') }}</h3>
      <div class="flex-sb">
        <div class="icon_btn blue_btn" @click="derive" v-checkAuth="'data-route-export'">
          <el-icon class="element_icon">
            <Upload />
          </el-icon>&nbsp;{{ t('647') }}
        </div>
        <div class="icon_btn blue_btn" @click="leadin" v-checkAuth="'data-route-import'">
          <el-icon class="element_icon">
            <Download />
          </el-icon>&nbsp;{{ t('648') }}
        </div>
        <div class="icon_btn red_btn" @click="airDel" v-checkAuth="'data-route-delete'">
          <el-icon class="element_icon">
            <Delete />
          </el-icon>&nbsp;{{ t('210') }}
        </div>
      </div>
    </div>
    <div class="btm flex">
      <div class="search flex-a">
        <el-input class="rolePut m2 common-input" type="text" :placeholder="t('226')"
          v-model.trim="queryForm.queryInfo" clearable @clear="inputClean" @keyup.enter="search" />
        <el-icon class="btn" style="cursor: pointer" @click="search">
          <Search />
        </el-icon>
      </div>
      <div class="equipment flex-a">
        <span>{{ t('771') }}:</span>
        <el-select style="width: 65%" class="common-input" :placeholder="t('175')" v-model="queryForm.fromType"
          @change="fromChange" clearable @clear="onClean">
          <!--  style="width: 65%" v-model="queryForm.modelId" -->
          <el-option v-for="item in routeFromList" :key="item.value" :label="item.desc" :value="item.value" />
        </el-select>
      </div>
      <div class="equipment flex-a">
        <span>{{ t('772') }}:</span>
        <el-select style="width: 65%" class="common-input" :placeholder="t('175')" v-model="queryForm.routeType"
          @change="downChange" clearable @clear="onClean">
          <!--  style="width: 65%" v-model="queryForm.modelId" -->
          <el-option v-for="item in routeDownList" :key="item.value" :label="item.desc" :value="item.value" />
        </el-select>
      </div>
      <div class="dataTime flex-sb">
        <span>{{ t('773') }}:</span>
        <el-date-picker class="m-2 common-input" format="YYYY-MM-DD" value-format="x" v-model="queryForm.startDate"
          type="date" :placeholder="t('774')" @change="startChange" />
        <span style="margin-left: 0.6875rem; margin-right: 0.6875rem">-</span>
        <el-date-picker class="m-2 common-input" format="YYYY-MM-DD" value-format="x" v-model="queryForm.endDate"
          type="date" :placeholder="t('774')" @change="endChange" />
      </div>
    </div>
  </div>
  <div class="airContent">
    <ele-table :tableColumnArray="tableColumnArray" :tableData="tableData" v-model:pageNo="queryForm.pageNo"
      v-model:pageSize="queryForm.pageSize" v-model:total="queryForm.total" @handleNoChange="routeList"
      @handleSizeChange="routeList" @handleSelectionChange="handleSelectionChange">
      <template v-slot="{ prop, row }">
        <span class="routeName" v-if="prop === 'routeName' && rowId !== row.routeId" @click="editAirName(row)">
          {{ row.routeName }}
        </span>
        <el-input ref="inputRef" @blur="changeName(row)" type="text" v-if="prop === 'routeName' && rowId === row.routeId"
          v-model.trim="row.routeName" onkeyup="this.value=this.value.replace(/\ +/g, '')" maxlength="50" />
      </template>
      <template v-slot:buttonHanlder>
        <el-table-column :label="t('610')" align="center" width="200px">
          <template v-slot="{ row }">
            <el-row>
              <el-col :span="row.fromType === 'PLANNING' ? 8 : 12">
                <span class="blue_btn" @click.stop="assign(row)" v-checkAuth="'data-route-update'">{{ t('775') }}</span>
              </el-col>
              <el-col :span="row.fromType === 'PLANNING' ? 8 : 12">
                <span class="blue_btn" @click.stop="preview(row)">{{ t('391') }}</span>
              </el-col>
              <el-col :span="8" v-if="row.fromType === 'PLANNING'">
                <span class="blue_btn" @click.stop="edit(row)">{{ t('674') }}</span>
              </el-col>
              <!-- <el-col :span="5">
                <span class="blue_btn" @click.stop="rename(row)">{{ t('776') }}</span>
              </el-col> -->
            </el-row>
          </template>
        </el-table-column>
      </template>
    </ele-table>
    <!-- 分配 -->
    <div class="animate__fadeInRight distribute" v-if="distributeShow">
      <div class="distribute_top">
        <div class="close flex-sb">
          <span>{{ t('775') }}</span>
          <el-icon class="cicon" style="cursor: pointer" @click="closeAssign">
            <CloseBold />
          </el-icon>
        </div>
        <div class="tab flex-a">
          <div style="cursor: pointer" class="nav" :class="item.id == navId ? 'navtive' : ''" v-for="item in navList"
            :key="item.id">
            <!-- @click="navChange(item)" -->
            {{ item.name }}
          </div>
        </div>
        <div class="searchs flex-a">
          <el-input class="filePut m2 common-input" type="text" :placeholder="navId == 1 ? '请输入机场名称' : '请输入终端名称'"
            v-model.trim="filterText" clearable @clear="inputchange" @keyup.enter="inputchange" />
          <el-icon class="file_icon" style="cursor: pointer" @click="inputchange">
            <Search />
          </el-icon>
        </div>
      </div>
      <div class="assignList">
        <!-- :class="{ changeTreeWidth: changeTreeWidth }"
         highlight-current0
          :default-expand-all="allExpand"
          :default-expanded-keys="expandedKeys"
          :expand-on-click-node="false"
          :filter-node-method="filterNode"
         -->
        <el-tree class="custom_tree" ref="elTree" :data="navData" :props="defaultProps" node-key="item_key"
          :default-expand-all="true" :filter="true" @node-click="nodeClick" :filter-node-method="filterNode"
          :render-content="renderNode">
          <template v-slot="{ node }">
            <!-- </template> -->
            <div v-if="node.data.type == 'region'" class="flex-a">
              <img class="img_list" src="@/asset/img/li_icon.png" alt="" />
              <span class="city_name">{{ node.label }}</span>
              <span class="navname">{{ node.data.num }}{{ navId == 1 ? t('1362') : t('1363') }}</span>
            </div>
            <div v-else class="item_content flex-sb flex" @click="itemChange(node.data)">
              <div class="flex-a">
                <img class="item_img" :src="navId == 1
                    ? require('@/asset/img/air.png')
                    : require('@/asset/img/phone.png')
                  " alt="" />
                <span class="city_name">{{ node.label }}</span>
              </div>
              <div class="collRight">
                <span class="span_g" v-if="node.data.assign == false" @click.stop="assignMent(true, node)">{{ t('775')
                }}</span>
                <span class="span_r" v-else @click.stop="assignMent(false, node)">{{ t('376') }}</span>
              </div>
            </div>
          </template>
        </el-tree>
      </div>
    </div>

    <!-- 预览 -->
    <div class="preview" v-if="previewShow">
      <div class="close flex-fe">
        <el-icon class="cicon" style="cursor: pointer" @click="closePreview">
          <CloseBold />
        </el-icon>
      </div>
      <div class="previewBox">
        <AirCesium :previewType="'route'" :previewId="operateRowId" />
      </div>
    </div>
  </div>

  <!-- 添加 -->
  <Dialog v-if="airIsVisible" :dialogTitle="editTitle" :isVisible="airIsVisible" :dialogWidth="'31.5rem'"
    @on-cancel="addCancel" @on-add="sava" class="addbox">
    <el-form ref="uploadFormRef" :model="uploadForm" :rules="uploadFormRules" label-width="80px" label-position="top">
      <div class="uploadBox">
        <el-form-item :label="t('777')" prop="fileUpload">
          <el-upload v-show="isShowUpload" :show-file-list="false" class="airFile" ref="uploadRef" :auto-upload="false"
            drag :headers="{
              token: $store.state.user.token,
              tenant: 'DEFAULT',
            }" :on-change="fileBeforeUpload" :action="actionUrl" :on-success="fileUploadSuccess"
            :on-error="uploadError" :http-request="httpUploadFile" :data="uploadForm"
            v-model:file-list="uploadForm.fileUpload" :limit="1" accept=".kml,.kmz,.json,.zip">
            <div class="airBox">{{ t('778') }}</div>
          </el-upload>
          <div class="ready-upload-box flex-fac flex-dc" v-if="!isShowUpload">
            <div class="airCenter flex-dc flex-fac">
              <img src="@/asset/img/air_dr.png" alt="" />
              <span>{{ fileName }}</span>
            </div>
          </div>
        </el-form-item>

        <div class="icon_btn red_btn del_btn" @click.stop="delUpload" v-if="!isShowUpload">
          <el-icon class="element_icon">
            <Delete />
          </el-icon>&nbsp;<span style="color: #ffffff; font-size: 0.875rem">{{ t('210') }}</span>
        </div>
      </div>
      <el-form-item :label="t('779')" prop="airwayName">
        <el-input class="m2 common-input in_bg" v-model.trim="uploadForm.airwayName"
          onkeyup="this.value=this.value.replace(/\ +/g, '')" :placeholder="t('780')" maxlength="50"></el-input>
      </el-form-item>
      <el-form-item :label="t('772')" prop="airwayType">
        <el-select class="m2 common-input" style="width: 100%" :placeholder="t('175')" v-model="uploadForm.airwayType"
          @change="addRouteChange" clearable @clear="onClean">
          <el-option class="el_text" :class="item.desc == uploadForm.airwayType ? 'el_active' : ''"
            v-for="item in routeDownList" :key="item.value" :label="item.desc" :value="item.value" />
        </el-select>
      </el-form-item>
    </el-form>
  </Dialog>

  <!-- 删除弹窗 -->
  <Dialog v-if="delShow" :dialogTitle="delDialogTitle" :isVisible="delShow" @on-add="delSubmit" @on-cancel="delCancel"
    class="addbox">
    <div><span class="del">!</span> {{ t('781') }}？</div>
  </Dialog>

  <!-- 重命名 -->
  <Dialog v-if="editNameShow" :dialogTitle="editName" :isVisible="editNameShow" :dialogWidth="'400px'"
    @on-add="editSubmit" @on-cancel="editCancel" class="editNameBox">
    <el-form ref="editNameRefs" :model="airNameFrom" :rules="airRules" label-width="200px" label-position="top">
      <el-form-item :label="t('400')" prop="routeName">
        <el-input style="width: 100%" class="common-input" v-model.trim="airNameFrom.routeName"
          onkeyup="this.value=this.value.replace(/\ +/g, '')" :placeholder="t('226')" maxlength="16"></el-input>
      </el-form-item>
    </el-form>
  </Dialog>
</template>

<script>
import { t } from '../../languages';
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted,
  computed,
  nextTick,
} from "vue";
import "animate.css";
import Dialog from "../../components/package/ele-dialog.vue";
import { ElMessage,ElMessageBox } from "element-plus";
import { router } from "@/router";
import dataRoute from "../../network/data_route";
import SparkMD5 from "spark-md5";
import device from "../../network/device";
import downListExcel from "../../network/download";
import AirCesium from "../../components/page/data-manage/components/airCesium.vue";
export default defineComponent({
  components: {
    Dialog,
    AirCesium,
  },
  setup(props, { emit }) {
    const uploadFormRef = ref(null);
    const editNameRefs = ref(null);
    const elTree = ref(null);
    const uploadRef = ref(null);
    const filterText = ref("");
    const state = reactive({
      startTime: "",
      endTime: "",
      airIsVisible: false, //添加的
      isShowUpload: true,
      // 是否上传过
      fileExist: false,
      fileMd5: "",
      fileName: "",
      // 上传
      renameForm: {
        airwayName: "",
        airwayId: "",
      },
      uploadFormRules: {
        fileUpload: [
          {
            required: true,
            message: t("782"),
            trigger: ["change", "blur"],
          },
        ],
        airwayType: [
          {
            required: true,
            message: t("783"),
            trigger: ["change", "blur"],
          },
        ],
        airwayName: [
          {
            required: true,
            message: t("226"),
            trigger: ["change", "blur"],
          },
        ],
      },
      //上传文件附带参数
      uploadForm: {
        airwayType: "",
        airwayName: "",
        fileUpload: [],//上传文件列表
      },
      editTitle: t("784"),
      delShow: false, //删除
      delAir: [], //对选列表
      ids: [], //删除id集合
      delDialogTitle: t("130"),
      previewShow: false,
      operateRowId: "", //操作的表格行id
      // 分配
      distributeShow: false,
      isDistribution: false,
      distributeType: "AIRPORT", //机场还是终端类型
      isDistribuSN: "",
      distributeFrom: {
        isDistribution: false, //是否分配
        routeId: 0, //航线id
        routeSn: "", //航线sn
      }, //分配表单
      belongingId: [], // 当前航线SN
      item_key: 0, //当前选中改行分配的key
      editNameShow: false, //重命名
      editName: t("776"),
      // 重命名规则
      airNameFrom: {
        routeId: 0,
        routeName: "",
      },
      //规则
      airRules: {
        routeName: [
          { required: true, message: t("226"), trigger: "change" },
        ],
      },
      navList: [
        { name: t("164"), id: 1, type: "AIRPORT" },
        // { name: t("165"), id: 2, type: "UAV" },
      ],
      navId: 1, //机场终端选择id
      drawShow: false, //是否显示抽屉

      // 假数据navData
      navData: [],
      // 配置
      defaultProps: {
        children: "children",
        label: "deviceName",
      },
      // 树节点的唯一标识的属性
      nodeKey: "",
      // 分页
      queryForm: {
        queryInfo: "",
        fromType: "",
        routeType: "",
        startDate: "",
        endDate: "",
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      // 航线类型
      routeDownList: [],
      // 航线来源
      routeFromList: [],
      // 表头
      tableColumnArray: [
        { label: t("400"), prop: "routeName" },
        { label: t("771"), prop: "fromTypeDesc" },
        { label: t("594"), prop: "routeTypeName" },
        { label: t("785"), prop: "routeSize" },
        { label: t("412"), prop: "createTime" },
        { label: t("411"), prop: "builderName" },
      ],
      // 表体数据
      tableData: [],
      // 新增数据
      addRouteObj: {
        md5: "",
        routeTypeEnum: "",
        airwayName: "",
      },
    });
    onMounted(() => {
      methods.downList();
      methods.fromList();
      methods.routeList();
    });
    const actionUrl = computed(() => {
      return process.env.VUE_APP_BASE_API + "/file/v1/file/upload";
    });
    const methods = reactive({
      // 列表数据
      routeList: async () => {
        let res = await dataRoute.routeList(state.queryForm);
        if (res.resultStatus) {
          state.queryForm.total = res.resultData.total;
          state.tableData = res.resultData.data;
        }
      },
      // 航线类型
      downList: async () => {
        let res = await dataRoute.routeDownList();
        if (res.resultStatus) {
          state.routeDownList = res.resultData.downTrees;
        }
      },
      downChange: () => {
        methods.routeList();
      },
      // 航线来源
      fromList: async () => {
        let res = await dataRoute.routeFromType();
        if (res.resultStatus) {
          state.routeFromList = res.resultData.downTrees;
        }
      },
      fromChange: () => {
        methods.routeList();
      },
      onClean: () => { },
      // 顶部搜索
      search: () => {
        methods.routeList();
      },
      inputClean: () => {
        methods.routeList();
      },
      //导出
      derive: () => {
        if (state.delAir == "") {
          ElMessage.warning(t("786"));
        } else {
          state.ids = []; //去除上一次数据
          state.delAir.map((item) => state.ids.push(item.routeId));
          downListExcel.downloadSource(
            "/api/v1/export/route/zip",
            {
              ids: state.ids,
            },
            "application/zip",
            "post"
          );
        }
      },
      //导入
      leadin: () => {
        state.airIsVisible = !state.airIsVisible;
      },
      addCancel: () => {
        state.airIsVisible = false;
        state.fileName = "";
        state.uploadForm.airwayName = "";
        state.uploadForm.airwayType = "";
        state.uploadForm.fileUpload = [];
        state.isShowUpload = true;
      },
      // 多选事件
      handleSelectionChange: (e) => {
        state.delAir = e;
      },
      //删除
      airDel: () => {
        if (state.delAir == "") {
          ElMessage.warning(t("620"));
        } else {
          state.ids = []; //去除上一次数据
          state.delAir.map((item) => state.ids.push(item.routeId));
          state.delShow = true;
        }
      },
      //确定删除
      delSubmit: async () => {
        let ids = state.ids;
        let res = await dataRoute.delRoute({ ids });
        if (res.resultStatus) {
          ElMessage.success(t("560"));
          methods.routeList();
          state.delShow = false;
        }
      },
      delCancel: () => {
        state.delShow = false;
      },
      //分配
      assign: (row) => {
        //routeId 航线ID
        state.distributeShow = !state.distributeShow;
        methods.navListData(state.navList[0].type);
        state.distributeFrom.routeId = row.routeId; //赋值当前分配航线ID
        // belongingId.value = row.belongingId; //拿这个检查是否已分配sn SN001|SN002|SN003
        let belongArr = [];
        if (row.belongingId != "") {
          belongArr = row.belongingId.split("|"); //切割赋值
          state.belongingId = belongArr;
        }
      },
      // 关闭分配
      closeAssign: () => {
        state.distributeShow = false;
        // 重置
        state.belongingId = [];
        state.navId = 1;
        state.navData = [];
      },
      // 分配/取消分配
      assignMent: async (isType, node) => {
        if (isType) {
          const res = await dataRoute.previewRoute(state.distributeFrom.routeId);
          const saleHeight = await dataRoute.getTaskHitht(node.data.sn)
          /* 对比高度 */
          if (res.resultStatus && saleHeight.resultStatus) {
            const flyHeights = res.resultData.previewMissionList
            const isTip = flyHeights.find((item) => {
              return item.wayPointAltitude < saleHeight.resultData
            })
            if (isTip) {
              ElMessageBox.confirm(
                '航线全局飞行高度低于安全飞行高度，是否继续分配?',
                '提示',
                {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }
              )
                .then(() => {
                  methods.getAssignMent(node, isType)
                })
                .catch(() => {
                  ElMessage({
                    type: 'info',
                    message: '取消分配',
                  })
                })
            } else {
              methods.getAssignMent(node, isType)
            }
          }

        } else {
          methods.getAssignMent(node, isType)
        }



      },
      // 分配
      async getAssignMent(node, isType) {
        state.distributeFrom.routeSn = node.data.sn;
        state.distributeFrom.isDistribution = isType;
        let res = await dataRoute.routeSetting(state.distributeFrom);
        if (res.resultStatus) {
          if (isType) {
            state.belongingId.push(state.distributeFrom.routeSn);
            node.data.assign = isType;
            ElMessage.success(t("787"));
            methods.routeList();
          } else {
            node.data.assign = isType;
            ElMessage.warning(t("788"));
            state.belongingId.map((item, index) => {
              if (item == state.distributeFrom.routeSn) {
                state.belongingId.splice(index, 1);
              }
            });
            methods.routeList();
          }
        }
      },
      //详情
      preview: async (row) => {
        state.operateRowId = row.routeId;
        state.previewShow = !state.previewShow;
      },
      // 关闭预览
      closePreview: () => {
        state.previewShow = false;
      },
      //编辑
      edit: (row) => {
        router.push({
          name: "airlinePlan",
          query: { value: "updateRoutePlan", airPlaneId: row.routeId },
        });
      },
      //重命名
      // rename: (row) => {
      //   state.airNameFrom.routeId = row.routeId;
      //   state.airNameFrom.routeName = row.routeName;
      //   state.editNameShow = !state.editNameShow;
      // },
      // editCancel: () => {
      //   state.editNameShow = false;
      // },
      // 重命名保存
      // editSubmit: () => {
      //   editNameRefs.value.validate(async (valid) => {
      //     if (valid) {
      //       let res = await dataRoute.routeEditName(state.airNameFrom);
      //       if (res.resultStatus) {
      //         ElMessage.success(t("714"));
      //         methods.routeList();
      //         state.editNameShow = false;
      //       }
      //     } else {
      //       return false;
      //     }
      //   });
      // },
      //机场终端切换
      navChange: (item) => {
        state.navId = item.id;
        state.distributeType = item.type;
        methods.navListData(item.type);
        state.navData = [];
      },
      // 切换请求
      navListData: async (type) => {
        let res = await device.getDeviveRegion(type);
        if (res.resultStatus) {
          let obj = deepCopy(res.resultData);
          state.navData = obj;
        }
      },
      // itemList列表内的子元素
      itemChange: () => {
        // 后面要换成ID,不然其他的也会联动点亮
        // state.itemId = row.id;
        // state.distributeFrom.routeSn = row.sn;
      },
      // 开始时间
      startChange: (e) => {
        if (e != null) {
          let res = +new Date(e) >= +new Date(state.queryForm.endDate);
          if (res) {
            state.queryForm.startDate = "";
            ElMessage.warning(t("789"));
          } else {
            let time = +new Date(e);
            state.queryForm.startDate = time;
            methods.routeList();
          }
        } else {
          state.queryForm.startDate = "";
          methods.routeList();
        }
      },
      // 结束时间
      endChange: (e) => {
        if (e != null) {
          let res = +new Date(e) <= +new Date(state.queryForm.startDate);
          if (res) {
            state.queryForm.endDate = "";
            ElMessage.warning(t("790"));
          } else {
            let time = +new Date(e);
            state.queryForm.endDate = time + 86400000 - 1000;
            methods.routeList();
          }
        } else {
          state.queryForm.endDate = "";
          methods.routeList();
        }
      },

      // 新增航线类型选择
      addRouteChange: (e) => {
        state.addRouteObj.routeTypeEnum = e;
      },
      // 上传文件
      fileBeforeUpload: (file) => {
        uploadFormRef.value.validateField("fileUpload", () => { });
        if (file.raw) {
          // let suf = file.raw.name.split(".");
          // if (suf[1] === "zip") {
          //   const name = file.name;
          //   if (name !== "") {
          //     state.fileName = name;
          //     state.isShowUpload = false;
          //     const res = name.split(".");
          //     state.uploadForm.airwayName = res[0];
          //   }
          // } else {
          const fileReader = new FileReader();
          fileReader.readAsBinaryString(file.raw);
          fileReader.onload = async (e) => {
            state.fileMd5 = SparkMD5.hashBinary(e.target.result);
            const data = await dataRoute.inspectFile(state.fileMd5);
            if (data instanceof Object && data.finished === true) {
              state.fileExist = true;
              // ElMessage.warning(t('791'));
              state.addRouteObj.md5 = data.resultData.md5;
              state.addRouteObj.airwayName = data.resultData.fileName;
              // state.addRouteObj.routeSize = Number(data.resultData.fileSize);
              // state.addRouteObj.routeUrl = data.resultData.url;
            }
          };
          const name = file.name;
          if (name !== "") {
            state.fileName = name;
            state.isShowUpload = false;
            const res = name.split(".");
            state.uploadForm.airwayName = res[0];
          }
          // }
        }
      },
      // 文件上传成功回调
      fileUploadSuccess: (res) => {
        let status = res.resultStatus;
        if (status) {
          ElMessage.success(t("792"));
          methods.routeList();
          state.airIsVisible = false;
          state.isShowUpload = true;
          state.fileName = "";
          state.uploadForm.airwayType = "";
          state.uploadForm.fileUpload = [];
        }
      },
      // 失败的回调
      uploadError: (err) => {
        console.info(err);
      },
      // 允许自行上传文件
      httpUploadFile: (option) => {
        if (state.fileExist) {
          methods.addRoute();
        } else {
          dataRoute
            .routeUpload(
              {
                file: option.file,
                systemName: "route",
                md5: state.fileMd5,
              },
              true
            )
            .then((res) => {
              if (res.resultStatus) {
                state.addRouteObj.md5 = res.resultData.md5;
                state.addRouteObj.airwayName = state.uploadForm.airwayName;
                // state.addRouteObj.routeSize = Number(res.resultData.fileSize);
                // state.addRouteObj.routeUrl = res.resultData.url;
                methods.addRoute();
              }
            });
        }
      },
      // 大疆绿土航线
      addRoute: async () => {
        let res = await dataRoute.addRoute(state.addRouteObj);
        if (res.resultStatus) {
          ElMessage.success(t("792"));
          methods.routeList();
          state.airIsVisible = false;
          state.isShowUpload = true;
          state.fileName = "";
          state.uploadForm.airwayName = "";
          state.uploadForm.airwayType = "";
          state.uploadForm.fileUpload = [];
        }
      },
      // 删除文件
      delUpload: () => {
        state.fileName = "";
        state.uploadForm.airwayName = "";
        state.isShowUpload = true;
        state.uploadForm.fileUpload = [];
        uploadFormRef.value.validateField("fileUpload", () => { });
      },
      // 确认上传
      sava: () => {
        uploadFormRef.value.validate((vali) => {
          if (vali) {
            // console.log(uploadRef.value, 'uploadRef');
            uploadRef.value.submit();
          } else {
            return false;
          }
        });
      },
    });
    /* 树节点的属性配置 */
    /* 过滤节点 */
    function filterNode(value, data) {
      // console.log(value)
      // console.log(data)
      if (!value) return true;
      return data[state.defaultProps.label].indexOf(value) !== -1;
    }
    /* 点击树节点 */
    function nodeClick(nodeData, dataNode, nodeCom) {
      emit("node-click", nodeData, dataNode, nodeCom);
    }

    /* 手动选中树节点  key为唯一标识（必须设置node-key） */
    function setCurrentKey(key) {
      // console.log(elTree.value.setCurrentKey(key))
      elTree.value.setCurrentKey(key);
    }
    /* 输入框改变 */
    function inputchange() {
      // console.log(filterText.value)
      elTree.value.filter(filterText.value);
      // let sn='666'
      // elTree.value.filter(sn);
    }

    function deepCopy(source) {
      // if (typeof(source) !== Object && source !== null) return source //如果不是对象的话直接返回
      const target = []; //Array.isArray(source) ? [] : {}; //数组兼容
      for (const k in source) {
        if (typeof source[k] === "object") {
          target[k] = deepCopy(source[k]);
        } else {
          target[k] = source[k];
        }
      }
      if (target.sn != undefined || target.sn != null) {
        if (state.belongingId.length != "") {
          state.belongingId.map((item) => {
            if (item == target.sn) {
              target.assign = true;
            }
          });
        }
      }
      return target;
    }
    //悬浮修改航线名称
    const rowId = ref(null);
    const inputRef = ref(null);
    const editAirName = (rowData) => {
      if (flag.value) {
        rowId.value = rowData.routeId;
      }
      nextTick(() => {
        inputRef.value.focus();
      });
    };
    const flag = ref(true);
    const changeName = async (rowData) => {
      if (rowData.routeName.length === 0) {
        ElMessage.error(t("793"));
        flag.value = false;
        return;
      }

      let res = await dataRoute.routeEditName(
        rowData.routeId,
        rowData.routeName
      );
      if (res.resultStatus) {
        ElMessage.success({
          duration: 1000,
          message: t("714"),
        });
        rowId.value = "";
        flag.value = true;
      } else {
        flag.value = false;
      }
    };

    return {
      ...toRefs(state),
      ...toRefs(methods),
      editNameRefs,
      uploadFormRef,
      uploadRef,
      actionUrl,
      elTree,
      nodeClick,
      setCurrentKey,
      inputchange,
      filterNode,
      filterText,
      deepCopy,
      rowId,
      editAirName,
      changeName,
      inputRef,
      // fromList,
      // downList,
      // routeList
    };
  },
});
</script>

<style scoped lang="less">
.navRight {
  height: 6.5625rem;
  margin-left: 23.75rem;
  background: rgba(0, 28, 49, 0.8);
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  padding: 0.9375rem 1.5625rem 0.75rem 1.375rem;

  /deep/.el-input__wrapper {
    height: 2.5rem;
    width: 100%;
  }

  .top {
    height: 1.25rem;
  }

  h3 {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #36e5ff;
  }

  .element_icon {
    font-size: 1.125rem;
  }

  .btm {
    margin-top: 1.25rem;
    width: 100%;
    box-sizing: border-box;

    .search {
      margin-right: 1.8125rem;

      /deep/.el-input__wrapper {
        border-radius: 4px 0px 0px 4px !important;
      }

      .rolePut {
        width: 15rem;
        height: 2.5rem;
        box-sizing: border-box;
        // border: 1px solid #51d2ff;
        // padding-left: 1.0625rem;
        background: #0d4460;
        color: #ffffff;
        outline: 0;
        border-radius: 4px 0px 0px 4px;
      }

      input::-webkit-input-placeholder {
        color: #a9b8c3;
      }

      .btn {
        width: 4.625rem;
        height: 2.5rem;
        background: #0090da;
        border-radius: 0px 4px 4px 0px;
        color: #ffffff;
        font-size: 1.625rem;
      }
    }

    .dataTime {
      color: #35e1fb;

      /deep/.el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 9.6875rem !important;
      }

      span {
        display: block;
        margin-right: 0.875rem;
      }
    }

    .equipment {
      width: 17.75rem;
      margin-right: 0.3125rem;
      color: #35e1fb;

      span {
        display: block;
        margin-right: 0.875rem;
      }
    }
  }
}

.airContent {
  width: 100%;
  height: calc(100% - 7.0625rem);
  background: rgba(0, 28, 49, 0.9);
  padding: 0 2.0625rem;
  box-sizing: border-box;

  .routeName {
    cursor: url("../../asset/img/edit-pen.png"), auto;
  }

  .routeName:hover {
    opacity: 0.7;
  }

  /deep/ .el-table tr {
    background-color: none !important;
  }

  /deep/ .el-table__body {
    box-sizing: border-box;
    overflow: hidden;
    width: 100% !important;
  }

  //body边距
  /deep/ .el-table__row {
    height: 3.75rem !important;
    border-radius: 0 8px 8px 0 !important;
  }

  /deep/tr .el-table__row {
    border-radius: 0 8px 8px 0 !important;
  }

  /deep/ .table-wrapper {
    position: initial;
  }

  // 分页
  /deep/ .el_pageination {
    right: 2.125rem;
  }

  /deep/ .el_pageination .el-input_wrapper {
    background: rgba(0, 28, 49, 0.8) !important;
  }

  //下拉背景图片
  /deep/ .el-select .el-input__wrapper {
    background: rgba(0, 28, 49, 0.8) !important;
  }

  /deep/ .el-input--small .el-input__wrapper {
    background: rgba(0, 28, 49, 0.8) !important;
  }
}

.del {
  display: inline-block;
  background: #e6a33d;
  color: #ffffff;
  width: 1.625rem;
  height: 1.625rem;
  line-height: 1.625rem;
  text-align: center;
  border-radius: 50%;
  margin-right: 0.9375rem;
}

.preview {
  position: absolute;
  top: 0;
  left: -5.55rem;
  right: -3.25rem;
  bottom: -1.875rem;
  z-index: 888;
  background: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;

  .close {
    height: 3.5625rem;

    .cicon {
      color: #ffffff;
      font-size: 1.75rem;
      margin-right: 2.4375rem;
    }
  }

  .previewBox {
    position: absolute;
    top: 5.125rem;
    left: 12.1875rem;
    right: 12.125rem;
    bottom: 5.1875rem;
  }
}

// 导入
.fileName {
  margin-bottom: 1.375rem;

  .filePut {
    width: 100%;
    height: 2.5rem;
    box-sizing: border-box;
    border: 1px solid #51d2ff;
    padding-left: 1.0625rem;
    background: #001c31;
    color: #ffffff;
    outline: 0;
  }

  input::-webkit-input-placeholder {
    color: #a9b8c3;
  }
}

.airModel {
  margin-bottom: 1.375rem;

  /deep/ .el-select .el-input__wrapper {
    background: rgba(0, 28, 49, 0.8) !important;
    height: 2.5rem;
    border-radius: 0;
  }
}

.airspan {
  display: block;
  margin-bottom: 0.5625rem;
  font-size: 1rem;
  color: #36e5ff;
}

.dialog_bg {
  background: #004375;

  /deep/.el-dialog {
    background: #004375 !important;
  }

  /deep/.el-dialog__body {
    border-top: 1px solid #037f91;
    padding: 0.8125rem 1.25rem 1.25rem;
  }

  /deep/.el-dialog__title {
    color: #ffffff;
  }
}

// 分配
.distribute {
  position: fixed;
  top: 6.25rem;
  left: 75%;
  right: 0;
  bottom: 0;
  z-index: 888;
  height: auto;
  overflow: auto;
  background: #06151e;

  .distribute_top {
    position: fixed;
    top: 6.25rem;
    left: 75%;
    right: 0;
    background: #06151e;
    z-index: 2500;

    .close {
      height: 4.9375rem;
      border-bottom: 1px solid #003335;

      span {
        display: inline-block;
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        margin-left: 2.1875rem;
      }

      .cicon {
        color: #ffffff;
        font-size: 1.75rem;
        margin-right: 1.875rem;
      }
    }

    .tab {
      width: 100%;
      height: 3.5rem;
      box-sizing: border-box;

      .nav {
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 3.5rem;
        color: #ffffff;
        font-size: 1rem;
      }

      .navtive {
        background: #0e99c1;
      }
    }

    .searchs {
      width: 100%;
      height: 3.375rem;
      line-height: 3.375rem;
      background: rgba(35, 163, 236, 0.4);

      .filePut {
        width: 90%;
        height: 3.375rem;
        box-sizing: border-box;
        border: 0px solid #51d2ff;
        padding-left: 1.0625rem;
        color: #ffffff;
        outline: 0;
        background: rgba(35, 163, 236, 0);
      }

      input::-webkit-input-placeholder {
        color: #a9b8c3;
      }

      .file_icon {
        width: 10%;
        height: 100%;
        margin-right: 1.25rem;
        font-size: 1.625rem;
        color: #ffffff;
        font-weight: 500;
      }

      /deep/.common-input .el-input__wrapper {
        border: 0px solid #51d2ff !important;
        background: none !important;
      }
    }
  }

  .assignList {
    margin-top: 11.8125rem;

    /deep/ .el-tree-node:focus>.el-tree-node__content {
      background: @blue;
      color: #fff; //背景色
    }

    /deep/ .el-tree-node__content {
      &:hover {
        background: @blue;
        color: #fff;
      }
    }

    /deep/ .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
      background: @blue;
      color: #fff; //背景色
    }

    // 结构树样式
    .item_content {
      width: 100%;
    }

    .coll_bg {
      width: 100%;
      background: #0e99c1 !important;
    }

    /deep/.el-tree {
      background: #06151e !important;
      --el-tree-node-hover-bg-color: #06151e !important; //移入样式
    }

    /deep/.el-tree-node__content {
      height: 3.625rem;
    }

    .img_list {
      margin-left: 0.625rem;
      margin-right: 1.0625rem;
      width: 1.4375rem;
      height: 0.9375rem;
      display: block;
    }

    .item_img {
      margin-left: 0.625rem;
      margin-right: 1.0625rem;
      width: 1.4375rem;
      height: 1.75rem;
      display: block;
    }

    .collRight {
      margin-right: 1.25rem;
      font-size: 0.875rem;

      .span_r {
        color: #e3232c;
      }

      .span_g {
        color: #23e350;
      }
    }

    .custom_tree {
      height: 100%;

      .el-icon {
        font-size: 0.75rem;
      }
    }

    .city_name {
      color: #00f5ff;
    }

    .navname {
      margin-left: 1rem;
      color: #ffffff;
    }

    .collTitle {
      img {
        width: 1.4375rem;
        height: 0.9375rem;
        margin-left: 2.25rem;
      }

      span {
        font-size: 1rem;
        margin-left: 1.0625rem;
        color: #00f5ff;
      }

      span:last-child {
        margin-left: 1.125rem;
        color: #ffffff;
      }
    }

    .coollItem {
      height: 4.25rem;
      border-top: 1px solid #166fa8;
      box-sizing: border-box;

      img {
        width: 1.5rem;
        height: 1.3125rem;
        margin-left: 4.6875rem;
      }

      .collLeft {
        color: #ffffff;
        font-size: 0.875rem;
        margin-left: 1.125rem;
      }
    }

    .coollItem:last-child {
      border-bottom: 1px solid #166fa8;
    }
  }
}

.addbox {
  /deep/.el-input__wrapper {
    background: rgba(0, 28, 49, 0.8) !important;
  }

  /deep/.el-dialog__body {
    margin-bottom: 1.875rem !important;
  }

  /deep/.el-form-item .el-form-item__label {
    margin-bottom: 0.25rem !important;
    color: #36e5ff;
    font-size: 1rem;
  }

  /deep/.el-input__wrapper {
    height: 2.5rem !important;
    border-radius: 0 !important;
  }

  /deep/.el-dialog__close {
    color: #36e5ff;
  }
}

.editNameBox {
  /deep/.el-form-item .el-form-item__label {
    margin-bottom: 0.25rem !important;
    color: #36e5ff;
    font-size: 0.875rem;
  }

  /deep/.el-dialog__close {
    color: #36e5ff;
  }
}

.el_active {
  color: #138fcf;
}

.el_text {
  text-align: center;
}

.uploadBox {
  position: relative;

  .del_btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  /deep/.el-form-item__content {
    display: flex;
    width: 100%;
  }

  /deep/.el-upload-dragger {
    padding: 0;
    background-color: none;
    border-radius: 0;
    border: 0px;
  }
}

.airFile {
  width: 100%;

  .airBox {
    width: 100%;
    height: 7.0625rem;
    line-height: 7.0625rem;
    border: 1px solid #51d2ff;
    color: #36e5ff;
    background: #001c31;
  }
}

.ready-upload-box {
  width: 100%;
  height: 7.0625rem;
  border: 1px solid #51d2ff;
  background: #001c31;
  box-sizing: border-box;

  .airCenter {
    margin: 0.3125rem 0.3125rem;
    box-sizing: border-box;

    img {
      display: block;
      width: 3.4375rem;
      height: 2.875rem;
      margin-bottom: 0.625rem;
    }

    span {
      display: block;
      height: 1.25rem;
      line-height: 1.25rem;
      text-align: center;
      font-size: 0.75rem;
    }
  }
}
</style>